import React from 'react';
import { graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DieVerkuendigungDesBab = ({ data }) => (
    <InnerPage title="Der Báb (1819-1850)" description="">
        <IntroHolder
            title="Der Báb (1819-1850)"
            subtitle="Der Báb, Vorbote der Bahá’í-Religion"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Bahá’u’lláh und Sein Anspruch',
                'current-item': 'Der Báb (1819-1850)',
            }}
        >
            <IntroQuote>
                Sein Leben ist eines der großartigsten Beispiele von Mut, das
                die Menschheit zu betrachten das Vorrecht hatte …
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Mit der Verkündigung des „Báb“ (dt. „Tor“) am 23. Mai 1844
                    begann eine neue Ära der Religionsgeschichte. Seit
                    Jahrhunderten erwarteten Menschen der verschiedensten
                    Religionen einen „Tag Gottes“, an dem Friede und Harmonie
                    auf Erden errichtet würden. Der Anbruch dieses Tages wurde
                    durch zwei kurz aufeinanderfolgende Gottesoffenbarer, den
                    Báb und Bahá’u’lláh verkündet. Ihre Offenbarungen setzten
                    jene Kräfte frei, welche die für diese Verheißung notwendige
                    Umgestaltung der Gesellschaft bewirken können. Im Jahr 2019
                    feierten Millionen Menschen weltweit den <a href="https://200jahrfeier.bahai.de/">200. Jahrestag der
                    Geburt des Báb</a>.
                </p>
                <div>
                    <h2>Offenbarung</h2>
                    <p>
                        Der Báb wurde am 20. Oktober 1819 in Schiras (Iran) mit
                        dem Namen Siyyid ‚Alí-Muhammad geboren. Schon als Kind
                        zeigte sich, dass Er mit angeborenem Wissen begabt war.
                        Im Alter von 25 Jahren verkündete Er Seine Sendung als
                        Gottesoffenbarer und Herold eines neuen Zeitalters –
                        dieses Ereignis wird auch als „Erklärung“ des Báb
                        bezeichnet.
                    </p>
                    <p>
                        Zu dieser Zeit bestand in verschiedenen islamischen
                        Glaubensrichtungen die Erwartung eines Verheißenen.
                        Unter den Führern entsprechender Bewegungen Persiens war
                        der Gelehrte Siyyid Kázim, der in der Stadt Karbilá
                        lebte. Dieser weihte sein Leben der Aufgabe, seine
                        Schüler auf das Kommen des von den Schiiten erwarteten
                        12. Imams, des „Qá’im“ (dt. „Er, der sich erhebt“),
                        vorzubereiten.
                    </p>
                    <p>
                        Einer seiner herausragendsten Schüler war Mullá Husayn,
                        der sich nach dem Tod seines Lehrers in die Stadt
                        Schiras begab, um dort den erwarteten Verheißenen zu
                        suchen. Am Vorabend des 23. Mai 1844 wurde Mullá Husayn
                        von einem außergewöhnlichen jungen Mann angesprochen,
                        der ihn in Sein Haus einlud. Der Gastgeber offenbarte
                        Sich in der darauffolgenden Nacht Mullá Husayn als der
                        „Báb“, als „Tor zu Gott“. In heiligen Versen legte Er
                        dar, dass Er der Verheißene sei, Dessen Kommen seit
                        langem herbeigesehnt worden
                        sei. Gleichzeitig kündigte Er das baldige Kommen einer
                        noch größeren Offenbarung an, die sich über Persien
                        hinaus an die ganze Menschheit wenden würde. Die
                        Bábi-Religion war geboren, die später in der
                        Bahá’í-Religion aufgehen sollte.
                    </p>
                    <p>
                        Unmittelbar nach Mullá Husayn erkannten noch siebzehn
                        weitere Sucher unabhängig voneinander den Báb und Seine
                        Sendung. Dieser bezeichnete Seine ersten Anhänger als
                        die achtzehn „Buchstaben des Lebendigen“. Er sandte sie
                        aus, die frohe Botschaft zu verbreiten und die Menschen
                        auf das baldige Kommen Bahá’u’lláhs vorzubereiten. Unter
                        den „Buchstaben des Lebendigen“ war auch Táhirih, eine
                        außergewöhnliche Dichterin und Frauenrechtlerin, die als
                        erste Frau Persiens öffentlich als Zeichen der vom Báb
                        verkündeten Gleichberechtigung von Mann und Frau ihren
                        Schleier ablegte. Sie war es auch, die 1848 auf der
                        Konferenz von Badascht (Iran) ausrief, dass nunmehr die
                        Trennung vom Islam vollzogen sei.
                    </p>
                    <h2>Sein weiteres Wirken</h2>
                    <p>
                        Die Botschaft vom Anbruch eines neuen Zeitalters der
                        Religion Persiens verbreitete sich wie ein Lauffeuer
                        unter Theologiestudenten, Gelehrten und der breiten
                        Bevölkerung Persiens. Seine fortschrittlichen und für
                        die damalige Zeit revolutionären Lehren – z.B. mehr
                        Rechte für Frauen, allgemeine Schulbildung, Auflösung
                        des Klerus – stellten einen heftigen Bruch mit der alten
                        islamischen Ordnung dar.
                    </p>
                    <p>
                        Der Báb wurde in der Folge wiederholt an verschiedene
                        Orte Persiens verbannt, was jedoch nur dazu führte, dass
                        sich der Ruhm Seiner Lehren und Sein gewaltiges Ansehen
                        noch schneller verbreiteten. Von Seiner ersten
                        Verbannungsstation in Isfahan musste er nach Täbris
                        reisen, von wo aus Er in die Festung Máh-Kú im heutigen
                        Aserbaidschan deportiert wurde. Dort offenbarte Er den
                        „Persischen Bayán“, das wichtigste Seiner Werke. Hierin
                        kündigte Er unmissverständlich das Kommen einer weiteren
                        Offenbarung an, die größer als Seine eigene sein würde
                        und rief Seine Anhänger dazu auf, „Ihn, den Gott
                        offenbaren wird“ (Bahá’u’lláh) zu suchen. Der Bayán
                        enthielt außerdem die Grundlagen einer neuen
                        gesellschaftlichen Ordnung für Persien.
                    </p>
                    <p>
                        Nach einer weiteren Verbannung in die Festung Chihríq
                        verkündete der Báb schließlich im Jahr 1848 in Täbris
                        gegenüber dem versammelten Klerus:
                    </p>
                    <CenteredQuote referenceNumber="2">
                        Ich bin, Ich bin, Ich bin der Verheißene! Ich bin Der,
                        dessen Namen ihr seit tausend Jahren anruft, bei dessen
                        Erwähnung ihr euch erhebt, dessen Kommen zu erleben ihr
                        ersehnt und um dessen Offenbarung ihr Gott bittet, dass
                        Er ihre Stunde beschleunige…
                    </CenteredQuote>
                    <h2>Die Hinrichtung des Báb</h2>
                    <p>
                        Nachdem der Báb diesen Anspruch erhoben hatte, trachtete
                        der schiitische Klerus danach, Seinen Einfluss endgültig
                        auszulöschen. Schließlich ordnete der durch den Klerus
                        aufgewiegelte Premierminister Persiens die Hinrichtung
                        des Báb an. Am 9. Juli 1850 erlitt der Báb zusammen mit
                        Seinem getreuen Begleiter Anís in Täbris unter
                        dramatischen Umständen den Märtyrertod. Drei Regimente
                        zu je 250 Mann eröffneten auf einem Kasernenhof das
                        Feuer auf die beiden mit Seilen angebundenen Männer. Als
                        sich der Rauch verzog, war der Báb vor den Augen der
                        gewaltigen Menge von Zuschauern verschwunden. Er wurde
                        schließlich unverletzt in Seiner Zelle vorgefunden, wo
                        Er eine zuvor begonnene Unterredung mit Seinem Sekretär
                        beendete. Der mit der Hinrichtung beauftragte Kommandant
                        Sám Khán war hiervon so erschüttert, dass er mit seinen
                        Soldaten den Kasernenhof verließ. Ein anderes Regiment
                        musste herbeigerufen werden, das die Hinrichtung
                        durchführte.
                    </p>
                    <p>
                        In der Folgezeit wurden mehrere zehntausend Gläubige auf
                        gemeinsames Betreiben des schiitischen Klerus und der
                        weltlichen Herrscher Persiens auf grausame und qualvolle
                        Weise hingerichtet – unter ihnen auch die meisten der
                        achtzehn „Buchstaben des Lebendigen“. Zahllose weitere
                        Anhänger des Báb wurden gefoltert, verbannt oder
                        enteignet.
                    </p>
                    <p>
                        Das kurze Leben des Báb war geprägt von großem
                        äußerlichem Leid, aber auch von unermesslicher geistiger
                        Herrlichkeit. Seine letzte Ruhestätte am
                        Bahá’í-Weltzentrum in Haifa ist für die Bahá’í – nach
                        dem Schrein Bahá’u’lláhs – einer der heiligsten Orte der
                        Welt und jedes Jahr ersehntes Ziel für eine große Anzahl
                        von Pilgern.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={
                                data.schreinDesBabCollage.childImageSharp.fluid
                                    .src
                            }
                            className="aligncenter"
                        />
                    </p>
                    <h2>Die Ankündigung der Offenbarung Bahá’u’lláhs</h2>
                    <p>
                        Der Báb ließ über Seine ganze Offenbarung hinweg keinen
                        Zweifel daran, dass Er nur der Vorläufer einer noch
                        größeren Offenbarung sei. Diese Prophezeiung erfüllte
                        sich 1853, als Bahá’u’lláh Seine Berufung zum
                        Gottesoffenbarer für dieses Zeitalter erfuhr. Somit
                        dauerte die Sendung des Báb nur neun Jahre. Doch sie
                        setzte die notwendigen geistigen Kräfte frei, um die
                        ganze Menschheit auf die Botschaft Bahá’u’lláhs
                        vorzubereiten.
                    </p>
                    <CenteredQuote referenceNumber="3">
                        Dass eine so kurze Zeitspanne diese machtvolle,
                        wunderbare Offenbarung von Meiner Mir vorausgegangenen
                        Manifestation getrennt hat, ist ein Geheimnis, das kein
                        Mensch enträtseln, und ein Mysterium, das kein Geist
                        ergründen kann.
                    </CenteredQuote>
                    <p />
                    <p />
                    <p />
                    <p />
                </div>
                <Reference>
                    <p>
                        1. zitiert in: Nabils Bericht, Bd. 3, S. 531, Fußnote
                        104
                    </p>
                    <p>
                        2. Nábils Bericht aus den frühen Tagen der
                        Bahá’í-Offenbarung, Hofheim-Langenhain 1975, S. 344
                    </p>
                    <p>
                        3. Bahá’u’lláh, zitiert in: Shoghi Effendi, Die
                        Weltordnung Bahá’u’lláhs #183
                    </p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DieVerkuendigungDesBab;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "the-bab-feature-img.jpg" }) {
            ...fluidImage
        }
        schreinDesBabCollage: file(
            relativePath: { eq: "schrein-des-bab-collage.png" }
        ) {
            ...fluidImage
        }
    }
`;
